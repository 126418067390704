import React from "react";
import packingImg1 from "../../../../Assect/packing1.gif";
import packingImg2 from "../../../../Assect/packing2.gif";
import packingImg3 from "../../../../Assect/packing3.webp";
import packingImg4 from "../../../../Assect/Price3.png";
import googleRating from "../../../../Assect/google-rating.png";

const Packing = () => {
  return (
    <div>
      <main>
        <div className="bg-background-dark-gray">
          <div
            style={{
              backgroundImage:
                "url(https://cdn.sanity.io/images/bm4duuk0/production/00567bc15ffb61e06f579f2049796a8858a4d826-1440x758.png?fit=max&amp;auto=format)",
              backgroundPosition: "center",
            }}
            className="relative h-[280px] bg-cover bg-left sm:bg-center shadow sm:h-[50vh] sm:max-h-[540px] lg:h-[60vh] 2xl:max-h-[45vh]"
          >
            <div
              className="absolute inset-0 opacity-50"
              style={{
                background: "linear-gradient(to right, #79b3f9, #5c65f7)",
              }}
            ></div>
            <div className="bg-cover bg-center pt-12 md:pt-16 pb-8 md:pb-12 text-center sm:text-left h-full">
              <div className="relative h-full max-w-6xl m-auto px-6 sm:px-12">
                <div className="w-full sm:max-w-[61.8%] xl:max-w-[50%]">
                  <div className="text-2xl sm:text-3xl md:text-4xl xl:text-4.5xl font-semibold leading-snug sm:leading-snug xl:leading-tight whitespace-pre-line">
                    <p>
                      Our Packing
                      <br />
                      Services
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-cover bg-center">
            <div className="relative h-full max-w-6xl m-auto px-6 sm:px-12">
              <div className="leading-snug text-center sm:text-left space-y-8 w-full md:w-1/2 -mt-16 sm:-mt-24">
                <div className="md:text-lg space-y-6 prose-ul:space-y-2 prose max-w-full md:prose-li:text-lg prose-li:text-left prose-li:mx-4 lg:prose-li:mx-8 prose-strong:text-inherit marker:text-black marker:text-sm">
                  <div className="relative h-80 sm:h-96">
                    <span
                      style={{
                        boxSizing: "border-box",
                        display: "block",
                        overflow: "hidden",
                        width: "initial",
                        height: "initial",
                        background: "none",
                        opacity: 1,
                        border: 0,
                        margin: 0,
                        padding: 0,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                      }}
                    >
                      <img
                        alt="Packing a Banjo"
                        src={packingImg1}
                        decoding="async"
                        data-nimg="fill"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          boxSizing: "border-box",
                          padding: 0,
                          border: "none",
                          margin: "auto",
                          display: "block",
                          width: 0,
                          height: 0,
                          minWidth: "100%",
                          minWidth: "100%",
                          minHeight: "100%",
                          minHeight: "100%",
                          objectFit: "contain",
                        }}
                      />
                      <noscript>
                        <img
                          alt="Packing a Banjo"
                          sizes="100vw"
                          srcset="
                                            /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F563f942643eb7a996318681c41bb0ea3336d9e58-600x338.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=640&amp;q=75   640w,
                                            /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F563f942643eb7a996318681c41bb0ea3336d9e58-600x338.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=750&amp;q=75   750w,
                                            /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F563f942643eb7a996318681c41bb0ea3336d9e58-600x338.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=828&amp;q=75   828w,
                                            /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F563f942643eb7a996318681c41bb0ea3336d9e58-600x338.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=1080&amp;q=75 1080w,
                                            /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F563f942643eb7a996318681c41bb0ea3336d9e58-600x338.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=1200&amp;q=75 1200w,
                                            /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F563f942643eb7a996318681c41bb0ea3336d9e58-600x338.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=1920&amp;q=75 1920w,
                                            /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F563f942643eb7a996318681c41bb0ea3336d9e58-600x338.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=2048&amp;q=75 2048w,
                                            /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F563f942643eb7a996318681c41bb0ea3336d9e58-600x338.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=3840&amp;q=75 3840w
                                        "
                          src="/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F563f942643eb7a996318681c41bb0ea3336d9e58-600x338.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=3840&amp;q=75"
                          decoding="async"
                          data-nimg="fill"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            boxSizing: "border-box",
                            padding: 0,
                            border: "none",
                            margin: "auto",
                            display: "block",
                            width: 0,
                            height: 0,
                            minWidth: "100%",
                            minWidth: "100%",
                            minHeight: "100%",
                            minHeight: "100%",
                            objectFit: "contain",
                          }}
                          loading="lazy"
                        />
                      </noscript>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-cover bg-center pt-8 md:pt-12 pb-8 md:pb-12">
            <div className="relative h-full max-w-6xl m-auto px-6 sm:px-12">
              <div className="w-full md:w-4/5 flex flex-wrap justify-between sm:gap-x-8 text-center sm:text-left">
                <div className="sm:flex-1">
                  <div className=" text-3xl font-extrabold  ">
                    <h2>
                      We&#x27;ll pick it up, pack it for you, and ship it out.
                    </h2>
                  </div>
                  <div className="mt-6 sm:mt-12 hidden sm:block space-y-4">
                    <a target="_blank" href="/get-a-quote">
                      <button
                        className="rounded-full font-bold focus:outline-light-purple whitespace-nowrap px-12 py-4 bg-light-purple hover:bg-dark-purple disabled:bg-disabled-purple text-white mr-4 w-64"
                        rel="noopener noreferrer"
                        _key="0d5d2cdc7f6c"
                        _type="callToAction"
                        title="Get A Quote"
                        url="/get-a-quote"
                      >
                        Get A Quote
                      </button>
                    </a>
                  </div>
                </div>
                <div className="sm:flex-1 sm:text-lg prose">
                  <p>
                    We know the hassle of finding the right box and queuing at
                    the post office, so we’ll pick up your unpacked stuff and do
                    all the boring bits.
                    <br />
                    <br />
                    We take all items to our warehouse to be packed by
                    professional packers, using state-of-the-art equipment and
                    the best packing techniques. Not only does this save you the
                    hassle of acquiring a suitable box, but
                    <strong>
                      optimal packing actually reduces shipping costs.
                    </strong>
                  </p>
                </div>
              </div>
              <div className="block sm:hidden mt-8 text-center space-y-4">
                <a target="_blank" href="/get-a-quote">
                  <button
                    className="rounded-full font-bold focus:outline-light-purple whitespace-nowrap px-12 py-4 bg-light-purple hover:bg-dark-purple disabled:bg-disabled-purple text-white mr-4 w-64"
                    rel="noopener noreferrer"
                    _key="0d5d2cdc7f6c"
                    _type="callToAction"
                    title="Get A Quote"
                    url="/get-a-quote"
                  >
                    Get A Quote
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="bg-cover bg-center pt-8 md:pt-12 pb-8 md:pb-12">
            <div className="relative h-full max-w-6xl m-auto px-6 sm:px-12">
              <div className="leading-snug text-center sm:text-left space-y-8 w-full md:w-[61.8%]">
                <div className="leading-snug font-semibold text-3xl sm:text-4.5xl">
                  <h2>
                    <span style={{ color: "#6211cb" }}>
                      <strong>
                        Professional Packing Team and Shipping Materials
                      </strong>
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-cover bg-center pt-4 md:pt-6 pb-8 md:pb-12">
            <div className="relative h-full max-w-6xl m-auto px-6 sm:px-12">
              <div className="w-full relative flex flex-wrap sm:flex-row flex-col-reverse gap-y-10 justify-between text-center sm:text-left">
                <div className="w-full sm:w-1/2 grid">
                  <div className="sm:pr-[6%] space-y-8">
                    <div className="sm:text-lg prose marker:text-black marker:text-sm">
                      <p>
                        Fuss no more about where to buy bubble wrap in
                        Singapore.
                        <br />
                        <br />
                        We provide all shipping materials like bubble wrap,
                        foam, tape or box sizes. We’ll pack your item securely
                        and ensure the
                        <strong>
                          lowest possible volumetric weight so you save on
                          shipping costs!
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full sm:w-1/2 sm:mt-0 grid">
                  <div className="sm:pl-[6%] space-y-8">
                    <div className="sm:text-lg prose marker:text-black marker:text-sm">
                      <div className="relative h-80 sm:h-96">
                        <span
                          style={{
                            boxSizing: "border-box",
                            display: "block",
                            overflow: "hidden",
                            width: "initial",
                            height: "initial",
                            background: "none",
                            opacity: 1,
                            border: 0,
                            margin: 0,
                            padding: 0,
                            position: "absolute",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                          }}
                        >
                          <img
                            alt="Packing with bubble wrap into a tight-fitting box"
                            src={packingImg2}
                            decoding="async"
                            data-nimg="fill"
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              bottom: 0,
                              right: 0,
                              boxSizing: "border-box",
                              padding: 0,
                              border: "none",
                              margin: "auto",
                              display: "block",
                              width: 0,
                              height: 0,
                              minWidth: "100%",
                              minWidth: "100%",
                              minHeight: "100%",
                              minHeight: "100%",
                              objectFit: "contain",
                            }}
                          />
                          <noscript>
                            <img
                              alt="Packing with bubble wrap into a tight-fitting box"
                              sizes="100vw"
                              srcset="
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2Fd7889832f3c250f91566412f547403b2a62cdd6f-600x338.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=640&amp;q=75   640w,
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2Fd7889832f3c250f91566412f547403b2a62cdd6f-600x338.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=750&amp;q=75   750w,
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2Fd7889832f3c250f91566412f547403b2a62cdd6f-600x338.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=828&amp;q=75   828w,
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2Fd7889832f3c250f91566412f547403b2a62cdd6f-600x338.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=1080&amp;q=75 1080w,
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2Fd7889832f3c250f91566412f547403b2a62cdd6f-600x338.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=1200&amp;q=75 1200w,
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2Fd7889832f3c250f91566412f547403b2a62cdd6f-600x338.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=1920&amp;q=75 1920w,
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2Fd7889832f3c250f91566412f547403b2a62cdd6f-600x338.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=2048&amp;q=75 2048w,
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2Fd7889832f3c250f91566412f547403b2a62cdd6f-600x338.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=3840&amp;q=75 3840w
                                                "
                              src="/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2Fd7889832f3c250f91566412f547403b2a62cdd6f-600x338.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=3840&amp;q=75"
                              decoding="async"
                              data-nimg="fill"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                boxSizing: "border-box",
                                padding: 0,
                                border: "none",
                                margin: "auto",
                                display: "block",
                                width: 0,
                                height: 0,
                                minWidth: "100%",
                                minWidth: "100%",
                                minHeight: "100%",
                                minHeight: "100%",
                                objectFit: "contain",
                              }}
                              loading="lazy"
                            />
                          </noscript>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-cover bg-center pt-8 md:pt-12 pb-8 md:pb-12">
            <div className="relative h-full max-w-6xl m-auto px-6 sm:px-12">
              <div className="w-full relative flex flex-wrap sm:flex-row gap-y-10 justify-between text-center sm:text-left">
                <div className="w-full sm:w-[61.8%] grid">
                  <div className="sm:pr-[6%] space-y-8">
                    <div className="sm:text-lg prose marker:text-black marker:text-sm">
                      <div className="relative h-80 sm:h-96">
                        <span
                          style={{
                            boxSizing: "border-box",
                            display: "block",
                            overflow: "hidden",
                            width: "initial",
                            height: "initial",
                            background: "none",
                            opacity: 1,
                            border: 0,
                            margin: 0,
                            padding: 0,
                            position: "absolute",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                          }}
                        >
                          <img
                            alt="Packing with Instapak"
                            src={packingImg3}
                            decoding="async"
                            data-nimg="fill"
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              bottom: 0,
                              right: 0,
                              boxSizing: "border-box",
                              padding: 0,
                              border: "none",
                              margin: "auto",
                              display: "block",
                              width: 0,
                              height: 0,
                              minWidth: "100%",
                              minWidth: "100%",
                              minHeight: "100%",
                              minHeight: "100%",
                              objectFit: "contain",
                            }}
                          />
                          <noscript>
                            <img
                              alt="Packing with Instapak"
                              sizes="100vw"
                              srcset="
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2Fcfd5c377b0642ffccbedfd4bc058865c2209934c-640x360.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=640&amp;q=75   640w,
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2Fcfd5c377b0642ffccbedfd4bc058865c2209934c-640x360.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=750&amp;q=75   750w,
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2Fcfd5c377b0642ffccbedfd4bc058865c2209934c-640x360.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=828&amp;q=75   828w,
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2Fcfd5c377b0642ffccbedfd4bc058865c2209934c-640x360.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=1080&amp;q=75 1080w,
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2Fcfd5c377b0642ffccbedfd4bc058865c2209934c-640x360.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=1200&amp;q=75 1200w,
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2Fcfd5c377b0642ffccbedfd4bc058865c2209934c-640x360.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=1920&amp;q=75 1920w,
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2Fcfd5c377b0642ffccbedfd4bc058865c2209934c-640x360.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=2048&amp;q=75 2048w,
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2Fcfd5c377b0642ffccbedfd4bc058865c2209934c-640x360.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=3840&amp;q=75 3840w
                                                "
                              src="/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2Fcfd5c377b0642ffccbedfd4bc058865c2209934c-640x360.gif%3Ffit%3Dmax%26auto%3Dformat&amp;w=3840&amp;q=75"
                              decoding="async"
                              data-nimg="fill"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                boxSizing: "border-box",
                                padding: 0,
                                border: "none",
                                margin: "auto",
                                display: "block",
                                width: 0,
                                height: 0,
                                minWidth: "100%",
                                minWidth: "100%",
                                minHeight: "100%",
                                minHeight: "100%",
                                objectFit: "contain",
                              }}
                              loading="lazy"
                            />
                          </noscript>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full sm:w-[38.2%] sm:mt-0 grid items-center">
                  <div className="sm:pl-[6%] space-y-8">
                    <div className="leading-snug font-semibold text-black whitespace-pre-line text-base sm:text-lg">
                      <h3>
                        <strong>
                          <em>
                            Packing odd shaped items? Or packing fragile items
                            for shipping?
                          </em>
                        </strong>
                      </h3>
                    </div>
                    <div className="sm:text-lg prose marker:text-black marker:text-sm">
                      <p>
                        With our Instapak 901, shipping fragile items
                        internationally will be safe, secure and worry-free!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-cover bg-center pt-8 md:pt-12 pb-8 md:pb-12">
            <div className="relative h-full max-w-6xl m-auto px-6 sm:px-12">
              <div className="leading-snug text-center sm:text-left space-y-8 w-full">
                <div className="leading-snug font-semibold text-3xl sm:text-4.5xl">
                  <h2>
                    <span style={{ color: "#6211cb" }}>
                      <strong className=" font-extrabold">
                        Reduce Shipping Cost
                      </strong>
                    </span>
                  </h2>
                </div>
                <div className="md:text-lg space-y-6 prose-ul:space-y-2 prose max-w-full md:prose-li:text-lg prose-li:text-left prose-li:mx-4 lg:prose-li:mx-8 prose-strong:text-inherit marker:text-black marker:text-sm">
                  <p></p>
                  <div className="mt-8 youtubeContainer relative w-[100%] h-0 pb-[56.25%] rounded-lg truncate">
                    <div className="">
                      <div id="" className="">
                        <iframe
                          width="100%"
                          height="720"
                          src="https://www.youtube.com/embed/mw0sOFf_j28"
                          title="Packing a Banjo"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-cover bg-center pb-16 md:pb-24">
            <div className="relative h-full max-w-6xl m-auto px-6 sm:px-12">
              <div className="leading-snug text-center sm:text-left space-y-8 w-full md:w-1/2">
                <div className="leading-snug font-semibold text-xl sm:text-2xl">
                  <h3>
                    Yes. Our shipping rates are cheap, but they can be even
                    cheaper for you if your item is packed properly.
                  </h3>
                </div>
                <div className="md:text-lg space-y-6 prose-ul:space-y-2 prose max-w-full md:prose-li:text-lg prose-li:text-left prose-li:mx-4 lg:prose-li:mx-8 prose-strong:text-inherit marker:text-black marker:text-sm">
                  <p>
                    Check out how we saved one of our customer’s $65.9 worth of
                    shipping fees, with our packing services.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="bg-cover bg-center pt-12 md:pt-16 pb-12 md:pb-16 overflow-hidden"
            style={{ backgroundColor: "#fed430" }}
          >
            <div className="relative h-full max-w-6xl m-auto px-6 sm:px-12">
              <div className="hidden md:block absolute left-0 right-0 bottom-0 w-[45%] h-full -z-[0] translate-y-[37%] -translate-x-[10%]">
                <span
                  style={{
                    boxSizing: "border-box",
                    display: "inline-block",
                    overflow: "hidden",
                    width: "initial",
                    height: "initial",
                    background: "none",
                    opacity: 1,
                    border: 0,
                    margin: 0,
                    padding: 0,
                    position: "relative",
                    maxWidth: "100%",
                  }}
                >
                  <span
                    style={{
                      boxSizing: "border-box",
                      display: "block",
                      width: "initial",
                      height: "initial",
                      background: "none",
                      opacity: 1,
                      border: 0,
                      margin: 0,
                      padding: 0,
                      maxWidth: "100%",
                    }}
                  >
                    <img
                      style={{
                        display: "block",
                        maxWidth: "100%",
                        width: "initial",
                        height: "initial",
                        background: "none",
                        opacity: 1,
                        border: 0,
                        margin: 0,
                        padding: 0,
                      }}
                      alt="pic"
                      aria-hidden="true"
                      src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%271362%27%20height=%271166%27/%3e"
                    />
                  </span>
                  <img
                    alt="rating backdrop"
                    src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                    decoding="async"
                    data-nimg="intrinsic"
                    className="-translate-y-[calc((100%-443px)*0.625)]"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      boxSizing: "border-box",
                      padding: 0,
                      border: "none",
                      margin: "auto",
                      display: "block",
                      width: 0,
                      height: 0,
                      minWidth: "100%",
                      minWidth: "100%",
                      minHeight: "100%",
                      minHeight: "100%",
                    }}
                  />
                  <noscript>
                    <img
                      alt="rating backdrop"
                      srcset="/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Frating-guy.9852e89a.png&amp;w=1920&amp;q=75 1x, /_next/image?url=%2F_next%2Fstatic%2Fmedia%2Frating-guy.9852e89a.png&amp;w=3840&amp;q=75 2x"
                      src="/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Frating-guy.9852e89a.png&amp;w=3840&amp;q=75"
                      decoding="async"
                      data-nimg="intrinsic"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        boxSizing: "border-box",
                        padding: 0,
                        border: "none",
                        margin: "auto",
                        display: "block",
                        width: 0,
                        height: 0,
                        minWidth: "100%",
                        minWidth: "100%",
                        minHeight: "100%",
                        minHeight: "100%",
                      }}
                      className="-translate-y-[calc((100%-443px)*0.625)]"
                      loading="lazy"
                    />
                  </noscript>
                </span>
              </div>
              <div className="w-full relative flex flex-wrap sm:flex-row gap-y-4 justify-between text-center sm:text-left">
                <div className="w-full sm:w-1/3 relative">
                  <div className="sm:pr-[6%] space-y-8">
                    <div className="leading-snug font-semibold text-black whitespace-pre-line text-2xl xl:text-3xl">
                      <h4>
                        <span style={{ color: "#844ffa" }}>
                          Our shippers trust and recommend us.
                        </span>
                      </h4>
                      <div className="pt-3 w-[80%] md:w-[90%] mx-auto md:mx-0">
                        <span
                          style={{
                            boxSizing: "border-box",
                            display: "inline-block",
                            overflow: "hidden",
                            width: "initial",
                            height: "initial",
                            background: "none",
                            opacity: 1,
                            border: 0,
                            margin: 0,
                            padding: 0,
                            maxWidth: "100%",
                          }}
                        >
                          <span
                            style={{
                              boxSizing: "border-box",
                              display: "block",
                              width: "initial",
                              height: "initial",
                              background: "none",
                              opacity: 1,
                              border: 0,
                              margin: 0,
                              padding: 0,
                              maxWidth: "100%",
                            }}
                          >
                            <img
                              style={{
                                display: "block",
                                maxWidth: "100%",
                                width: "initial",
                                height: "initial",
                                background: "none",
                                opacity: 1,
                                border: 0,
                                margin: 0,
                                padding: 0,
                              }}
                              alt="kiser img"
                              aria-hidden="true"
                              src={googleRating}
                            />
                          </span>
                          <img
                            alt="google ratings"
                            src={packingImg4}
                            decoding="async"
                            data-nimg="intrinsic"
                            style={{
                              position: "absolute",
                              top: 400,
                              left: 0,
                              bottom: 0,
                              right: 0,
                              boxSizing: "border-box",
                              padding: 0,
                              border: "none",
                              margin: "auto",
                              display: "block",
                              width: "650px",
                              height: "400px",
                              minWidth: "100%",
                              background: "none",
                              maxWidth: "100%",
                              minHeight: "100%",
                              maxHeight: "100%",
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full sm:w-2/3">
                  <div className="sm:pl-[6%] space-y-8">
                    <div className="w-full">
                      <div className="w-[calc{100%)] mx-auto relative">
                        <button
                          className="absolute top-[50%] -translate-y-[50%] left-0 md:translate-x-16 lg:translate-x-10 rounded z-20 hover:bg-white/40 hover:text-dark-purple transition-all ease duration-300"
                          aria-label="Previous review"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="h-[42px] w-[24px] md:w-[30px] stroke-white"
                          >
                            <polyline points="15 18 9 12 15 6"></polyline>
                          </svg>
                        </button>
                        <div className="scene w-full h-[365px] lg:h-[360px]">
                          <div className="carousel overflow-hidden h-full w-full">
                            <div className="carousel__cell w-[70%] max-w-[270px] md:max-w-full md:w-[198px] h-[315px] sm:h-[320px] md:h-[261px] px-2 py-2 top-[50%] -translate-y-[50%] left-0 right-0 mx-auto text-ellipsis overflow-hidden md:scale-[1.30] z-[3] hover:bg-background-gray">
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.google.com/maps/contrib/107208738349943989016/place/ChIJsaCFU-cR2jERU5iJ6qjaP5g/@-14.8268161,112.3654196,4z/data=!4m6!1m5!8m4!1e1!2s107208738349943989016!3m1!1e1?hl=en-US"
                              >
                                <div className="h-full">
                                  <div className="flex items-center">
                                    <span
                                      style={{
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        overflow: "hidden",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        position: "relative",
                                        maxWidth: "100%",
                                      }}
                                    >
                                      <span
                                        style={{
                                          boxSizing: "border-box",
                                          display: "block",
                                          width: "initial",
                                          height: "initial",
                                          background: "none",
                                          opacity: 1,
                                          border: 0,
                                          margin: 0,
                                          padding: 0,
                                          maxWidth: "100%",
                                        }}
                                      >
                                        <img
                                          style={{
                                            display: "block",
                                            maxWidth: "100%",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                          }}
                                          alt="pic"
                                          aria-hidden="true"
                                          src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2725%27%20height=%2725%27/%3e"
                                        />
                                      </span>
                                      <img
                                        alt="profile picture"
                                        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                        decoding="async"
                                        data-nimg="intrinsic"
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          bottom: 0,
                                          right: 0,
                                          boxSizing: "border-box",
                                          padding: 0,
                                          border: "none",
                                          margin: "auto",
                                          display: "block",
                                          width: 0,
                                          height: 0,
                                          minWidth: "100%",
                                          minWidth: "100%",
                                          minHeight: "100%",
                                          minHeight: "100%",
                                        }}
                                      />
                                      <noscript>
                                        <img
                                          alt="profile picture"
                                          srcset="
                                                                            /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F72e5548ef4aaa38b9fc9f19d281f6e9dda3e44fb-45x45.png%3Ffit%3Dmax%26auto%3Dformat&amp;w=32&amp;q=75 1x,
                                                                            /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F72e5548ef4aaa38b9fc9f19d281f6e9dda3e44fb-45x45.png%3Ffit%3Dmax%26auto%3Dformat&amp;w=64&amp;q=75 2x
                                                                        "
                                          src="/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F72e5548ef4aaa38b9fc9f19d281f6e9dda3e44fb-45x45.png%3Ffit%3Dmax%26auto%3Dformat&amp;w=64&amp;q=75"
                                          decoding="async"
                                          data-nimg="intrinsic"
                                          style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            boxSizing: "border-box",
                                            padding: 0,
                                            border: "none",
                                            margin: "auto",
                                            display: "block",
                                            width: 0,
                                            height: 0,
                                            minWidth: "100%",
                                            minWidth: "100%",
                                            minHeight: "100%",
                                            minHeight: "100%",
                                          }}
                                          loading="lazy"
                                        />
                                      </noscript>
                                    </span>
                                    <p className="pl-2 text-sm md:text-xs text-[rgb(60,64,67)] font-[&#x27;Arial&#x27;] text-left mt-0.5 md:mt-0">
                                      Jyothi Johnson
                                    </p>
                                  </div>
                                  <div className="flex py-1.5">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="0"
                                      stroke="currentColor"
                                      className="w-4 h-4 fill-yellow"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                      ></path>
                                    </svg>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="0"
                                      stroke="currentColor"
                                      className="w-4 h-4 fill-yellow"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                      ></path>
                                    </svg>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="0"
                                      stroke="currentColor"
                                      className="w-4 h-4 fill-yellow"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                      ></path>
                                    </svg>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="0"
                                      stroke="currentColor"
                                      className="w-4 h-4 fill-yellow"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                      ></path>
                                    </svg>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="0"
                                      stroke="currentColor"
                                      className="w-4 h-4 fill-yellow"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                      ></path>
                                    </svg>
                                  </div>
                                  <div className="line-clamp-[12]">
                                    <p className="text-sm md:text-[11px] md:leading-4 text-[rgb(60,64,67)] font-[&#x27;Arial&#x27;] text-left">
                                      Good service. Products arrived in great
                                      condition. The packaging was great and I
                                      got regular status updates. Goods were
                                      picked up from Singapore and arrived in
                                      Sydney in 10 days
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div className="carousel__cell w-[70%] max-w-[270px] md:max-w-full md:w-[198px] h-[315px] sm:h-[320px] md:h-[261px] px-2 py-2 top-[50%] -translate-y-[50%] left-0 right-0 mx-auto text-ellipsis overflow-hidden translate-x-96 lg:translate-x-28 opacity-0 lg:opacity-100 z-[1] pointer-events-none">
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.google.com/maps/contrib/116482460450693541346/reviews?hl=en-US"
                              >
                                <div className="h-full">
                                  <div className="flex items-center">
                                    <span
                                      style={{
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        overflow: "hidden",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        position: "relative",
                                        maxWidth: "100%",
                                      }}
                                    >
                                      <span
                                        style={{
                                          boxSizing: "border-box",
                                          display: "block",
                                          width: "initial",
                                          height: "initial",
                                          background: "none",
                                          opacity: 1,
                                          border: 0,
                                          margin: 0,
                                          padding: 0,
                                          maxWidth: "100%",
                                        }}
                                      >
                                        <img
                                          style={{
                                            display: "block",
                                            maxWidth: "100%",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                          }}
                                          alt="pic"
                                          aria-hidden="true"
                                          src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2725%27%20height=%2725%27/%3e"
                                        />
                                      </span>
                                      <img
                                        alt="profile picture"
                                        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                        decoding="async"
                                        data-nimg="intrinsic"
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          bottom: 0,
                                          right: 0,
                                          boxSizing: "border-box",
                                          padding: 0,
                                          border: "none",
                                          margin: "auto",
                                          display: "block",
                                          width: 0,
                                          height: 0,
                                          minWidth: "100%",
                                          minWidth: "100%",
                                          minHeight: "100%",
                                          minHeight: "100%",
                                        }}
                                      />
                                      <noscript>
                                        <img
                                          alt="profile picture"
                                          srcset="
                                                                            /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F9fd61f218568f371aecaa01c52ea0bbf1f6afd21-45x45.png%3Ffit%3Dmax%26auto%3Dformat&amp;w=32&amp;q=75 1x,
                                                                            /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F9fd61f218568f371aecaa01c52ea0bbf1f6afd21-45x45.png%3Ffit%3Dmax%26auto%3Dformat&amp;w=64&amp;q=75 2x
                                                                        "
                                          src="/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F9fd61f218568f371aecaa01c52ea0bbf1f6afd21-45x45.png%3Ffit%3Dmax%26auto%3Dformat&amp;w=64&amp;q=75"
                                          decoding="async"
                                          data-nimg="intrinsic"
                                          style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            boxSizing: "border-box",
                                            padding: 0,
                                            border: "none",
                                            margin: "auto",
                                            display: "block",
                                            width: 0,
                                            height: 0,
                                            minWidth: "100%",
                                            minWidth: "100%",
                                            minHeight: "100%",
                                            minHeight: "100%",
                                          }}
                                          loading="lazy"
                                        />
                                      </noscript>
                                    </span>
                                    <p className="pl-2 text-sm md:text-xs text-[rgb(60,64,67)] font-[&#x27;Arial&#x27;] text-left mt-0.5 md:mt-0">
                                      Jovvy Chen
                                    </p>
                                  </div>
                                  <div className="flex py-1.5">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="0"
                                      stroke="currentColor"
                                      className="w-4 h-4 fill-yellow"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                      ></path>
                                    </svg>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="0"
                                      stroke="currentColor"
                                      className="w-4 h-4 fill-yellow"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                      ></path>
                                    </svg>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="0"
                                      stroke="currentColor"
                                      className="w-4 h-4 fill-yellow"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                      ></path>
                                    </svg>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="0"
                                      stroke="currentColor"
                                      className="w-4 h-4 fill-yellow"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                      ></path>
                                    </svg>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="0"
                                      stroke="currentColor"
                                      className="w-4 h-4 fill-yellow"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                      ></path>
                                    </svg>
                                  </div>
                                  <div className="line-clamp-[12]">
                                    <p className="text-sm md:text-[11px] md:leading-4 text-[rgb(60,64,67)] font-[&#x27;Arial&#x27;] text-left">
                                      Fast delivery &amp; excellent packing.
                                      Item arrived quickly &amp; safely; very
                                      satisfied!
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div className="carousel__cell w-[70%] max-w-[270px] md:max-w-full md:w-[198px] h-[315px] sm:h-[320px] md:h-[261px] px-2 py-2 top-[50%] -translate-y-[50%] left-0 right-0 mx-auto text-ellipsis overflow-hidden -translate-x-96 lg:-translate-x-28 opacity-0 lg:opacity-100 z-[2] pointer-events-none">
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.google.com/maps/contrib/101307377446265018850/place/ChIJsaCFU-cR2jERU5iJ6qjaP5g/@1.3268908,99.4159367,6z/data=!4m4!1m3!8m2!1e1!2s101307377446265018850?hl=en-US"
                              >
                                <div className="h-full">
                                  <div className="flex items-center">
                                    <span
                                      style={{
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        overflow: "hidden",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        position: "relative",
                                        maxWidth: "100%",
                                      }}
                                    >
                                      <span
                                        style={{
                                          boxSizing: "border-box",
                                          display: "block",
                                          width: "initial",
                                          height: "initial",
                                          background: "none",
                                          opacity: 1,
                                          border: 0,
                                          margin: 0,
                                          padding: 0,
                                          maxWidth: "100%",
                                        }}
                                      >
                                        <img
                                          style={{
                                            display: "block",
                                            maxWidth: "100%",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                          }}
                                          alt="pic"
                                          aria-hidden="true"
                                          src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2725%27%20height=%2725%27/%3e"
                                        />
                                      </span>
                                      <img
                                        alt="profile picture"
                                        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                        decoding="async"
                                        data-nimg="intrinsic"
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          bottom: 0,
                                          right: 0,
                                          boxSizing: "border-box",
                                          padding: 0,
                                          border: "none",
                                          margin: "auto",
                                          display: "block",
                                          width: 0,
                                          height: 0,
                                          minWidth: "100%",
                                          minWidth: "100%",
                                          minHeight: "100%",
                                          minHeight: "100%",
                                        }}
                                      />
                                      <noscript>
                                        <img
                                          alt="profile picture"
                                          srcset="
                                                                            /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2Fcff53d6fd477fbc036e0b84fd5e4187ae2780c4d-45x45.png%3Ffit%3Dmax%26auto%3Dformat&amp;w=32&amp;q=75 1x,
                                                                            /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2Fcff53d6fd477fbc036e0b84fd5e4187ae2780c4d-45x45.png%3Ffit%3Dmax%26auto%3Dformat&amp;w=64&amp;q=75 2x
                                                                        "
                                          src="/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2Fcff53d6fd477fbc036e0b84fd5e4187ae2780c4d-45x45.png%3Ffit%3Dmax%26auto%3Dformat&amp;w=64&amp;q=75"
                                          decoding="async"
                                          data-nimg="intrinsic"
                                          style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            boxSizing: "border-box",
                                            padding: 0,
                                            border: "none",
                                            margin: "auto",
                                            display: "block",
                                            width: 0,
                                            height: 0,
                                            minWidth: "100%",
                                            minWidth: "100%",
                                            minHeight: "100%",
                                            minHeight: "100%",
                                          }}
                                          loading="lazy"
                                        />
                                      </noscript>
                                    </span>
                                    <p className="pl-2 text-sm md:text-xs text-[rgb(60,64,67)] font-[&#x27;Arial&#x27;] text-left mt-0.5 md:mt-0">
                                      Yuri Flaherty
                                    </p>
                                  </div>
                                  <div className="flex py-1.5">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="0"
                                      stroke="currentColor"
                                      className="w-4 h-4 fill-yellow"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                      ></path>
                                    </svg>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="0"
                                      stroke="currentColor"
                                      className="w-4 h-4 fill-yellow"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                      ></path>
                                    </svg>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="0"
                                      stroke="currentColor"
                                      className="w-4 h-4 fill-yellow"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                      ></path>
                                    </svg>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="0"
                                      stroke="currentColor"
                                      className="w-4 h-4 fill-yellow"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                      ></path>
                                    </svg>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="0"
                                      stroke="currentColor"
                                      className="w-4 h-4 fill-yellow"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                      ></path>
                                    </svg>
                                  </div>
                                  <div className="line-clamp-[12]">
                                    <p className="text-sm md:text-[11px] md:leading-4 text-[rgb(60,64,67)] font-[&#x27;Arial&#x27;] text-left">
                                      JustShip is the best of the best. They
                                      managed a complicated and high-value
                                      shipment for me from start to finish.
                                      Their packaging was also the best I’ve
                                      seen in a very long time. After this
                                      experience, they are the only shipper I
                                      will be using out of SG. Highly highly
                                      recommend you try them.
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <button
                          className="absolute top-[50%] -translate-y-[50%] right-0 md:-translate-x-16 lg:-translate-x-10 rounded z-20 hover:bg-white/40 hover:text-dark-purple transition-all ease duration-300"
                          aria-label="Next review"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="h-[42px] w-[24px] md:w-[30px] stroke-white"
                          >
                            <polyline points="9 18 15 12 9 6"></polyline>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="bg-cover bg-center pt-16 md:pt-24 pb-24 md:pb-32"
            style={{
              backgroundImage:
                "url(https://cdn.sanity.io/images/bm4duuk0/production/0f5cb52befac691bde6a9fc14be5e802d4b1fcde-1920x1080.svg?fit=max&amp;auto=format)",
              backgroundPosition: "center",
              backgroundColor: "#f8f9fa",
            }}
          >
            <div className="relative h-full max-w-6xl m-auto px-6 sm:px-12">
              <div className="leading-snug text-center sm:text-left space-y-8 w-full md:w-4/5">
                <div className="leading-snug font-semibold text-3xl sm:text-4.5xl">
                  <h2>
                    <span style={{ color: "#6211cb" }}>
                      <strong>FAQ</strong>
                    </span>
                  </h2>
                </div>
                <div className="md:text-lg space-y-6 prose-ul:space-y-2 prose max-w-full md:prose-li:text-lg prose-li:text-left prose-li:mx-4 lg:prose-li:mx-8 prose-strong:text-inherit marker:text-black marker:text-sm">
                  <h3>
                    <strong>Why can&#x27;t I just use any old box?</strong>
                  </h3>
                  <ul>
                    <li>
                      Shipping companies base shipping fees off how big your box
                      is. Even if you’re shipping a 0.3 kg item, stuffing it
                      into a bigger box means that you will be charged a
                      significantly higher fee.
                    </li>
                    <li>
                      Read our article:
                      <a
                        className="text-dark-purple hover:text-light-purple"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="/volumetric-weight-don-t-ever-forget-this"
                      >
                        Volumetric Weight? Don’t Ever Forget This to learn more
                      </a>
                    </li>
                  </ul>
                  <h3>
                    <strong>How much is packing?</strong>
                  </h3>
                  <ul>
                    <li>
                      Packing starts from only $3. Additional costs may apply
                      for packing fragile items or large items. Get an instant
                      quote now!
                    </li>
                  </ul>
                </div>
                <div className="pb-8 space-y-4">
                  <a target="_blank" href="/get-a-quote">
                    <button
                      className="rounded-full font-bold focus:outline-light-purple whitespace-nowrap px-12 py-4 bg-light-purple hover:bg-dark-purple disabled:bg-disabled-purple text-white mr-4 w-64 lg:w-56"
                      rel="noopener noreferrer"
                      _key="e6f7457e90da"
                      _type="callToAction"
                      title="Get A Quote"
                      url="/get-a-quote"
                    >
                      Get A Quote
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Packing;
