import React from "react";

const TaxRay = () => {
  return (
    <div>
      <main>
        <div className="bg-background-dark-gray">
          <div
            style={{
              backgroundImage:
                "url(https://cdn.sanity.io/images/bm4duuk0/production/d09c8c824974e49c77461496c713309cbb691359-1440x607.jpg?fit=max&amp;auto=format)",
              backgroundPosition: "center",
            }}
            className="relative h-[280px] bg-cover bg-left sm:bg-center shadow sm:h-[50vh] sm:max-h-[540px] lg:h-[60vh] 2xl:max-h-[45vh]"
          >
            <div
              className="absolute inset-0 opacity-50"
              style={{
                background: "linear-gradient(to right, #fd7167, #5c65f7)",
              }}
            ></div>
            <div className="bg-cover bg-center pt-12 md:pt-16 pb-8 md:pb-12 text-center sm:text-left h-full">
              <div className="relative h-full max-w-6xl m-auto px-6 sm:px-12">
                <div className="w-full sm:max-w-[61.8%] xl:max-w-[50%]">
                  <div className="text-2xl sm:text-3xl md:text-4xl xl:text-4.5xl font-semibold leading-snug sm:leading-snug xl:leading-tight whitespace-pre-line">
                    <h1>
                      <span style={{ color: "#ffffff" }}>
                        Stop worrying about taxes. Level up with JustShip.
                      </span>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-cover bg-center pt-12 md:pt-16 pb-8 md:pb-12">
            <div className="relative h-full max-w-6xl m-auto px-6 sm:px-12">
              <div className="leading-snug text-center sm:text-left space-y-8 w-full md:w-2/3">
                <div className="leading-snug font-semibold text-2xl md:text-3xl">
                  <h2>Taxes and Duties can be a nightmare for shippers</h2>
                </div>
                <div className="md:text-lg space-y-6 prose-ul:space-y-2 prose max-w-full md:prose-li:text-lg prose-li:text-left prose-li:mx-4 lg:prose-li:mx-8 prose-strong:text-inherit marker:text-black marker:text-sm">
                  <p>
                    When you ship something to another country, your recipient
                    may be asked to pay additional duties and taxes when the
                    shipment reaches the destination country’s customs.
                  </p>
                  <ul>
                    <li>
                      It can feel very uncertain as taxes are hard to estimate
                    </li>
                    <li>It hurts when it gets charged unexpectedly</li>
                    <li>
                      Oftentimes it&#x27;s too late to back out since the parcel
                      is already at the destination country
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-cover bg-center pt-8 md:pt-12">
            <div className="relative h-full max-w-6xl m-auto px-6 sm:px-12">
              <div className="leading-snug text-center sm:text-left space-y-8 w-full md:w-2/3">
                <div className="leading-snug font-semibold text-2xl md:text-3xl">
                  <p>What are duties and taxes for?</p>
                </div>
                <div className="md:text-lg space-y-6 prose-ul:space-y-2 prose max-w-full md:prose-li:text-lg prose-li:text-left prose-li:mx-4 lg:prose-li:mx-8 prose-strong:text-inherit marker:text-black marker:text-sm">
                  <p>
                    Governments tax shipments from other countries because they
                    want to:
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-cover bg-center pt-8 md:pt-12 pb-8 md:pb-12">
            <div className="relative h-full max-w-6xl m-auto px-6 sm:px-12">
              <div className="grid gap-y-12 md:gap-y-16 gap-x-6 md:gap-x-8 lg:gap-x-10 w-full md:w-2/3 grid-cols-1 sm:grid-cols-3">
                <div className="flex md:block md:pr-4">
                  <div className="inline-block w-1/4 md:block md:w-full text-4xl font-bold text-[#611ECB]">
                    <p className="text-center sm:text-left">01</p>
                  </div>
                  <div className="inline-block w-3/4 md:block md:w-full">
                    <div className="md:mt-2 font-medium text-lg text-[#611ECB] ">
                      <p>
                        Protect domestic companies from foreign competitors
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex md:block md:pr-4">
                  <div className="inline-block w-1/4 md:block md:w-full text-4xl font-bold text-[#611ECB]">
                    <p className="text-center sm:text-left">02</p>
                  </div>
                  <div className="inline-block w-3/4 md:block md:w-full">
                    <div className="md:mt-2 font-medium text-lg text-[#611ECB] ">
                      <p>Control the flow of certain products</p>
                    </div>
                  </div>
                </div>
                <div className="flex md:block md:pr-4">
                  <div className="inline-block w-1/4 md:block md:w-full text-4xl font-bold text-[#611ECB]">
                    <p className="text-center sm:text-left">03</p>
                  </div>
                  <div className="inline-block w-3/4 md:block md:w-full">
                    <div className="md:mt-2 font-medium text-lg text-[#611ECB] ">
                      <p>Raise revenue through taxes</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-cover bg-center pb-8 md:pb-12">
            <div className="relative h-full max-w-6xl m-auto px-6 sm:px-12">
              <div className="leading-snug text-center sm:text-left space-y-8 w-full md:w-2/3">
                <div className="md:text-lg space-y-6 prose-ul:space-y-2 prose max-w-full md:prose-li:text-lg prose-li:text-left prose-li:mx-4 lg:prose-li:mx-8 prose-strong:text-inherit marker:text-black marker:text-sm">
                  <p>
                    Duties and taxes on shipments are legal requirements that
                    must be settled before your shipment can be delivered.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-cover bg-center pt-8 md:pt-12 pb-8 md:pb-12">
            <div className="relative h-full max-w-6xl m-auto px-6 sm:px-12">
              <div className="leading-snug text-center sm:text-left space-y-8 w-full md:w-2/3">
                <div className="leading-snug font-semibold text-2xl md:text-3xl">
                  <h2>Will I get taxed?</h2>
                </div>
                <div className="md:text-lg space-y-6 prose-ul:space-y-2 prose max-w-full md:prose-li:text-lg prose-li:text-left prose-li:mx-4 lg:prose-li:mx-8 prose-strong:text-inherit marker:text-black marker:text-sm">
                  <p>
                    You will only get taxed when the value of your shipment
                    exceeds the tax threshold set by your destination country.
                    To learn more about these rules, visit our country-specific
                    pages.
                    <br />
                    <br />
                    <span style={{ color: "#858484" }}>
                      <em>
                        P.s. under-declaring the value of your item to avoid
                        taxes may cost you much more if caught. Also, if your
                        items are lost and you opted for insurance, you will
                        have lower coverage.
                      </em>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-cover bg-center pt-8 md:pt-12 pb-8 md:pb-12">
            <div className="relative h-full max-w-6xl m-auto px-6 sm:px-12">
              <div className="leading-snug text-center sm:text-left space-y-8 w-full md:w-2/3">
                <div className="leading-snug font-semibold text-2xl md:text-3xl">
                  <h2>Unlock your powers, get Tax-Ray vision</h2>
                </div>
                <div className="md:text-lg space-y-6 prose-ul:space-y-2 prose max-w-full md:prose-li:text-lg prose-li:text-left prose-li:mx-4 lg:prose-li:mx-8 prose-strong:text-inherit marker:text-black marker:text-sm">
                  <p>
                    Instantly know if you will get taxed while filling up our
                    order form.
                  </p>
                  <p>
                    Not expected to get taxed? Kick back and relax, you can ship
                    without worry.
                  </p>
                  <p></p>
                  <p>
                    <span style={{ color: "#858484" }}>
                      <em>
                        *a few shipments still get taxed when customs disagrees
                        with your declaration
                      </em>
                    </span>
                  </p>
                  <p></p>
                  <p>If taxes are expected:</p>
                  <ul>
                    <li>
                      You could have it charged to your recipient i.e. customs
                      will reach out to your recipient with the exact amount to
                      pay when the parcel reaches the destination.
                    </li>
                  </ul>
                  <p></p>
                  <ul>
                    <li>
                      <span style={{ color: "#6211cb" }}>
                        <strong>
                          You could opt for JustShip&#x27;s pre-paid option. We
                          quote you a fixed, upfront cost along with your
                          shipping quote. After that payment, expect no more
                          charges for taxes.
                        </strong>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-cover bg-center pt-8 md:pt-12 pb-8 md:pb-12">
            <div className="relative h-full max-w-6xl m-auto px-6 sm:px-12">
              <div className="leading-snug text-center sm:text-left space-y-8 w-full md:w-2/3">
                <div className="leading-snug font-semibold text-2xl md:text-3xl">
                  <h2>Get taxes out of the way, once and for all.</h2>
                </div>
                <div className="md:text-lg space-y-6 prose-ul:space-y-2 prose max-w-full md:prose-li:text-lg prose-li:text-left prose-li:mx-4 lg:prose-li:mx-8 prose-strong:text-inherit marker:text-black marker:text-sm">
                  <p>
                    Having your parcel delivered with taxes paid upfront has
                    clear benefits:
                  </p>
                  <ol>
                    <li>No more rude shocks.</li>
                    <li>
                      Your recipients are spared from paying. This is important
                      especially if your package is a gift.
                    </li>
                    <li>
                      Increase the speed of clearance for customs. Your parcel
                      will not need to be held at customs, pending payment
                      confirmation.
                    </li>
                    <li>
                      No more return shipments caused by your recipient
                      abandoning the shipment.
                    </li>
                    <li>
                      Money savings! Handling fees resulting from post-paid
                      taxes can be 3-4 times more costly.
                    </li>
                  </ol>
                  <p>
                    For now, JustShip charges a flat fee of $20 for
                    tax-handling. This is less than half the market rate, so you
                    save ~$30 right off the bat.
                  </p>
                  <p>
                    Furthermore, delivering with taxes paid upfront is a service
                    that shipping partners often reserve only for their larger
                    VIP customers.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-cover bg-center pt-12 md:pt-16 pb-16 md:pb-24">
            <div className="relative h-full max-w-6xl m-auto px-6 sm:px-12">
              <div className="leading-snug text-center sm:text-left space-y-8 w-full md:w-4/5">
                <div className="leading-snug font-semibold text-2xl md:text-3xl">
                  <h2>
                    <em>
                      Don&#x27;t let taxes hold you back from shipping.
                      <span style={{ color: "#6211cb" }}>
                        Get exclusive access to international shipping power-ups
                        now.
                      </span>
                    </em>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div
            className="bg-cover bg-center pt-16 md:pt-24"
            style={{ backgroundColor: "#844ffa" }}
          >
            <div className="relative h-full max-w-6xl m-auto px-6 sm:px-12">
              <div className="w-full relative flex flex-wrap sm:flex-row gap-y-10 justify-between text-center sm:text-left">
                <div className="w-full sm:w-1/2 grid">
                  <div className="sm:pr-[6%] space-y-8">
                    <div className="leading-snug font-semibold text-black whitespace-pre-line text-3xl sm:text-4.5xl">
                      <p>
                        <span style={{ color: "#ffffff" }}>
                          Get Started
                          <br />
                          In Minutes
                        </span>
                      </p>
                    </div>
                    <div className="pb-8 flex flex-wrap gap-4 justify-center sm:justify-start">
                      <a target="_blank" href="/create-shipment">
                        <button
                          className="rounded-full font-bold focus:outline-light-purple whitespace-nowrap px-12 py-4 bg-white hover:bg-dark-purple disabled:bg-background-dark-gray text-[#611ECB] hover:text-white shadow-sm w-64 lg:w-56"
                          rel="noopener noreferrer"
                          _key="3e6564629fcb"
                          _type="callToAction"
                          title="Ship Now"
                          url="/create-shipment"
                        >
                          Ship Now
                        </button>
                      </a>
                      <a target="_blank" href="/get-a-quote">
                        <button
                          className="rounded-full font-bold focus:outline-light-purple whitespace-nowrap px-12 py-4 border border-white hover:border-dark-purple bg-transparent hover:bg-dark-purple text-white hover:text-white shadow-sm w-64 lg:w-56"
                          rel="noopener noreferrer"
                          _key="a871268a74e1"
                          _type="callToAction"
                          title="Get A Quote"
                          url="/get-a-quote"
                        >
                          Get A Quote
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w-full sm:w-1/2 sm:mt-0 grid items-end">
                  <div className="sm:pl-[6%] space-y-8">
                    <div className="sm:text-lg prose marker:text-black marker:text-sm">
                      <div className="relative h-80 sm:h-96">
                        <span
                          style={{
                            boxSizing: "border-box",
                            display: "block",
                            overflow: "hidden",
                            width: "initial",
                            height: "initial",
                            background: "none",
                            opacity: 1,
                            border: 0,
                            margin: 0,
                            padding: 0,
                            position: "absolute",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                          }}
                        >
                          <img
                            alt="Get Started With A Quote"
                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                            decoding="async"
                            data-nimg="fill"
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              bottom: 0,
                              right: 0,
                              boxSizing: "border-box",
                              padding: 0,
                              border: "none",
                              margin: "auto",
                              display: "block",
                              width: 0,
                              height: 0,
                              minWidth: "100%",
                              maxWidth: "100%",
                              minHeight: "100%",
                              maxHeight: "100%",
                              objectFit: "contain",
                            }}
                          />
                          <noscript>
                            <img
                              alt="Get Started With A Quote"
                              sizes="100vw"
                              srcset="
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F78362593228b9fe115993d4faee7eda60d0084dd-1106x770.png%3Ffit%3Dmax%26auto%3Dformat&amp;w=640&amp;q=75   640w,
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F78362593228b9fe115993d4faee7eda60d0084dd-1106x770.png%3Ffit%3Dmax%26auto%3Dformat&amp;w=750&amp;q=75   750w,
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F78362593228b9fe115993d4faee7eda60d0084dd-1106x770.png%3Ffit%3Dmax%26auto%3Dformat&amp;w=828&amp;q=75   828w,
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F78362593228b9fe115993d4faee7eda60d0084dd-1106x770.png%3Ffit%3Dmax%26auto%3Dformat&amp;w=1080&amp;q=75 1080w,
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F78362593228b9fe115993d4faee7eda60d0084dd-1106x770.png%3Ffit%3Dmax%26auto%3Dformat&amp;w=1200&amp;q=75 1200w,
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F78362593228b9fe115993d4faee7eda60d0084dd-1106x770.png%3Ffit%3Dmax%26auto%3Dformat&amp;w=1920&amp;q=75 1920w,
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F78362593228b9fe115993d4faee7eda60d0084dd-1106x770.png%3Ffit%3Dmax%26auto%3Dformat&amp;w=2048&amp;q=75 2048w,
                                                    /_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F78362593228b9fe115993d4faee7eda60d0084dd-1106x770.png%3Ffit%3Dmax%26auto%3Dformat&amp;w=3840&amp;q=75 3840w
                                                "
                              src="/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fbm4duuk0%2Fproduction%2F78362593228b9fe115993d4faee7eda60d0084dd-1106x770.png%3Ffit%3Dmax%26auto%3Dformat&amp;w=3840&amp;q=75"
                              decoding="async"
                              data-nimg="fill"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                boxSizing: "border-box",
                                padding: 0,
                                border: "none",
                                margin: "auto",
                                display: "block",
                                width: 0,
                                height: 0,
                                minWidth: "100%",
                                maxWidth: "100%",
                                minHeight: "100%",
                                maxHeight: "100%",
                                objectFit: "contain",
                              }}
                              loading="lazy"
                            />
                          </noscript>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default TaxRay;
