import React from "react";
import Bannar from "./Bannar/Bannar";
import Wellcome from "./Wellcome/Wellcome";

const Hero = () => {
  return (
    <div>
      {/* <Wellcome></Wellcome> */}
      {/* <Bannar></Bannar> */}
    </div>
  );
};

export default Hero;
